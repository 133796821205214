export function closeCurrentTag(vue, next, fallback) {
  vue.$store.dispatch('delView', vue.$route).then(({ visitedViews }) => {
    const exitReason = vue.$store.getters['router/currentExitReason'];
    if (!exitReason) {
      vue.$store.dispatch('router/setExitReason', 'close');
    }
    const latestView = visitedViews[0];
    if (next) {
      vue.$router.push(next);
    } else if (latestView) {
      vue.$router.push(latestView);
    } else if (fallback) {
      vue.$router.push(fallback);
    } else {
      vue.$router.push('/');
    }
  });
}
