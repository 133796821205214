<template>
  <base-button
    class="editor-button"
    :disabled="disabled"
    :loading="loading"
    :type="type"
    :icon="icon"
    :icon-only="iconOnly"
    :caption="innerCaption"
    :svg-icon="svgIcon"
    :data-cy="action"
    @click="handleClick"
  />
</template>

<script>
import BaseButton from '@/components/crud/BaseButton';
export default {
  components: {
    BaseButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    iconOnly: {
      type: Boolean,
      default: true
    },
    caption: String,
    action: {
      type: String,
      default: '',
      validator: function(value) {
        // The value must match one of these strings
        return (
          ['add', 'save', 'cancel', 'saveClose', 'saveNext', 'delete', 'copy', 'clearFilters', 'document'].indexOf(
            value
          ) !== -1
        );
      }
    }
  },
  computed: {
    type() {
      switch (this.action) {
        case 'add':
        case 'save':
        case 'saveClose':
        case 'saveNext':
        case 'copy':
        case 'document':
          return 'primary';
        default:
          return '';
      }
    },
    icon() {
      switch (this.action) {
        case 'add':
          return 'el-icon-circle-plus';
        case 'cancel':
          return 'el-icon-close';
        case 'delete':
          return 'el-icon-delete';
        case 'copy':
          return 'el-icon-copy-document';
        case 'document':
          return 'el-icon-document';
        default:
          return '';
      }
    },
    innerCaption() {
      return this.caption ? this.$i18n.t(this.caption) : this.$i18n.t('common.' + this.action);
    },
    svgIcon() {
      if (this.loading) {
        return '';
      }
      switch (this.action) {
        case 'save':
          return 'save';
        case 'saveClose':
          return 'saveclose';
        case 'saveNext':
          return 'savenext';
        case 'clearFilters':
          return 'clearFilters';
        default:
          return '';
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-navbar button {
  &.icon-only {
    font-size: 19px;
  }
  .svg-container {
    margin-right: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .sub-navbar button {
    .svg-container {
      margin-left: 0px;
    }
  }
}
</style>
